import React from "react";
import { GenericError } from "../../components/GenericError/GenericError";
import { Layout } from "../../components/Layout";

// Key: DFVVXSOE.RUJ/ILQDOV/FXEH
// How to decrypt: caesar-shift -3

const SecretCube: React.FC = () => {
  return (
    <Layout hasFooter={false}>
      <GenericError
        error_message={"Looking for something...?"}
        sub_message={"...do ordain and establish this Constitution..."}
      />
    </Layout>
  );
};

export default SecretCube;
